

function loadLeafletMap() {
    var point = [$('address').data('latitude'), $('address').data('longitude')];
    var map = L.map('map', {'scrollWheelZoom': false}).setView(point, 15);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a rel="nofollow" target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    L.marker(point).addTo(map);
    //.bindPopup('A pretty CSS3 popup.<br> Easily customizable.')
    //.openPopup();
}

$(document).ready(function () {

    if ($('.map').length) {
        loadLeafletMap();
    }
});
